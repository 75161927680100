import { redirect } from '@remix-run/node'

import { searchHandler } from '../index'

import { AvailableApps } from '~/config/apps'

import getApp from '~/utils/getApp'

import {
    cleanUrl,
    cleanAppFromUrl
} from '~/utils/helpers'

import type { LoaderFunction } from '@remix-run/node'

export const loader: LoaderFunction = async (props) => {
    const app = getApp(props.request.url)

    if (app instanceof Response) {
        return app
    }

    if (app.id !== AvailableApps.Family) {
        const cleanedUrl = cleanAppFromUrl(cleanUrl(new URL(props.request.url).host))
        return redirect(`//${app.path}${cleanedUrl}/page-not-found`)
    }

    return await searchHandler(props)
}

export { default } from '../index'